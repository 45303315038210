import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authoriseRequest from "../../auth";
import { Culture } from "./CultureSlice";
import { PrimarySource } from "./PrimarySourceSlice";
import { Protocol } from "./ProtocolSlice";

const url = process.env.NODE_ENV === 'production' ? 
    'https://labman.corticallabs.com/api/cell/cultures/primary/' :  `http://${window.location.hostname}:8080/cell/cultures/primary/`

type RemoteState = 'idle' | 'loading' | 'succeeded' | 'failed'

export interface PrimaryCulture extends Culture {
    ID: number,
    UUID: string,
    MeaID: number,
    Inception: string,
    LastMediaChange: string,
    Density: number,
    AraC: boolean,
    Death: string,
    Comment: string,
    Protocol: Protocol,
    Reference: string,
    Source: PrimarySource
}

export const fetchPrimaryCultureList = createAsyncThunk("PrimaryCulture/getCultureList", async () => {
    try {
        const response = authoriseRequest(url, {
            method: 'GET'
        })

        return response        
    } catch (error) {
        console.log(error)
    }
})

export const fetchPrimaryCulture = createAsyncThunk("PrimaryCulture/getCulture", async(id: number) => {
    try {
        const response = authoriseRequest(url+`${id}`, {
            method: 'GET'
        })

        return response        
    } catch (error) {
        console.log(error)
    }
})

export const markPrimaryCultureDead = createAsyncThunk("PrimaryCulture/markDead", async (id: number) => {
    try {
        const response = authoriseRequest(url, {
            method: 'POST',
            body: JSON.stringify({'CMD': 'markDead', 'CultureID': id})
        }, true)

        return response
    } catch (error) {
        console.log(error)
    }
})

type PrimaryCultureState = {
    fetchCultureState: RemoteState,
    fetchCultureListState: RemoteState,
    editCultureState: RemoteState,
    deleteCulturState: RemoteState,
    markCultureDead: RemoteState,
    cultures: PrimaryCulture[]
}

const PrimaryCultureSlice = createSlice({
    name: "PrimaryCulture",
    initialState: {
        fetchCultureState: 'idle',
        fetchCultureListState: 'idle',
        editCultureState: 'idle',
        deleteCulturState: 'idle',
        markCultureDead: 'idle',
        cultures: []
    } as PrimaryCultureState,
    reducers: {
        resetFetchCultureState: (state) => {
            state.fetchCultureState = 'idle'
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchPrimaryCulture.pending, (state) => {
            state.fetchCultureState = 'loading'
        })
        builder.addCase(fetchPrimaryCulture.fulfilled, (state, action) => {
            const culture = action.payload[0]
            state.cultures.push(culture)
            state.fetchCultureState = 'succeeded'
        })
        builder.addCase(fetchPrimaryCultureList.pending, (state) => {
            state.fetchCultureListState = 'loading'
        })
        builder.addCase(fetchPrimaryCultureList.fulfilled, (state, action) => {
            state.fetchCultureListState = 'succeeded'
            state.cultures = action.payload
        })
        builder.addCase(fetchPrimaryCultureList.rejected, (state,action) => {
            state.fetchCultureListState = 'failed'
            console.log(action.error)
        })
        builder.addCase(markPrimaryCultureDead.pending, (state) => {
            state.markCultureDead = 'loading'
        })
        builder.addCase(markPrimaryCultureDead.fulfilled, (state, action) => {
            state.markCultureDead = 'succeeded'
            const cultureID = action.payload.CultureID
            const deathTime = action.payload.DeathTime

            const culture = state.cultures.find(culture => culture.ID == cultureID)
            if (culture !== undefined) {
                culture.Death = deathTime
            }
            
            console.log(action.payload)
        })
        builder.addCase(markPrimaryCultureDead.rejected, (state, action) => {
            state.markCultureDead = 'failed'
            console.log(action.error)
        })
    }
})

export default PrimaryCultureSlice