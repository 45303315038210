import store from "./redux/rootReducer"

const authoriseRequest = async (url: RequestInfo, requestObj: RequestInit, autoJSON?: boolean): Promise<any> => {
    if (process.env.NODE_ENV === 'production') {
        const token = store.getState().user.token
        if (token !== undefined) {
            requestObj.headers = {
                "Authorization": token
            }
        } else {
            throw Error("Unauthorized user")
        }
    }
    
    const response = await fetch(url, requestObj)

    if (!response.ok) {
        const err = await response.text()
        throw Error(err)
    }
    
    if (autoJSON === false) {
        return response
    }
    
    const json = await response.json()
    return json 
}

export default authoriseRequest