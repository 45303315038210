import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import {
  BrowserRouter as Router
} from 'react-router-dom'

import store from './redux/rootReducer'

import './index.scss'
import App from './app/App'
import { createMediaChange, MediaChangePayload } from './redux/media/MediaChangeSlice'

let previousKey: string
let isParsing = false

let qrCode = ''

type changeMediaStateType = 'idle' | 'initiated'

let changeMediaState: changeMediaStateType = 'idle'

let mediaID: number
let cultures: number[] = []


document.addEventListener('keydown', (e) => {
  if (previousKey === 'Shift' && e.key === '~') {
    if (isParsing) {
      
      isParsing = false
      let qrObject = JSON.parse(qrCode)
      
      if (qrObject.type === "changemedia") {
        if (changeMediaState === 'idle') {
          changeMediaState = 'initiated'
        } else {
          console.log(cultures)
          
          const payload = cultures.map(cultureID => {
            return {
              CultureID: cultureID,
              Datetime: new Date().toISOString(),
              UserID: store.getState().user.currentUser!.id,
              BatchID: mediaID
            }
          })

          store.dispatch(createMediaChange(payload))
          changeMediaState = 'idle'
        }
      }

      if (qrObject.type === "cancel") {
        if (changeMediaState === 'initiated') {
          changeMediaState = 'idle'
        }
      }

      if (changeMediaState === "initiated" && qrObject.type === "media") {
        mediaID = qrObject.id
      }

      if (changeMediaState === "initiated" && qrObject.type === "culture") {
        if (!cultures.includes(qrObject.id)) {
          cultures.push(qrObject.id)
        }
      }
      qrCode = ''
    } else {
      isParsing = true
    }
  }

  if (!(e.key === "Shift" || e.key === "~") && isParsing) {
    let char = e.key
    qrCode += char
  }

  previousKey = e.key
})

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)