import React, { Suspense, useState } from 'react';
import { CHeader, CSidebar, CToggler, CSubheader, CHeaderNav, CHeaderNavItem, CSidebarNav, CSidebarNavItem, CSidebarNavTitle, CBreadcrumbRouter, CSelect, CButtonGroup, CButton } from '@coreui/react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../redux/rootReducer'

import { Switch, Route, Link, useLocation } from 'react-router-dom';

import routes from './routes'
import { sidebarRoutes } from './routes'

import CIcon from '@coreui/icons-react'
import { cilSettings } from '@coreui/icons'
import Login from './Login';
import UserSlice from '../redux/UserSlice';
import { createSlice } from '@reduxjs/toolkit';
import VNCSlice from '../redux/VNC';

type SliceState = 'responsive' | boolean

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: 'responsive' as SliceState,
  reducers: {
    toggle: state => [true, 'responsive'].includes(state) ? false : 'responsive',
    toggleMobile: state => [false, 'responsive'].includes(state) ? true : 'responsive'
  }
})

function Sidebar() {
  const dispatch = useDispatch()

  const sidebarShow = useSelector((state: RootState) => state.sidebar)

  function generateSidebar() {
    const sidebarArray: Array<JSX.Element> = []
    let sidebarIndex = 0

    sidebarRoutes.forEach(group => {
      sidebarArray.push(<CSidebarNavTitle key={sidebarIndex}>{group.heading}</CSidebarNavTitle>)
      sidebarIndex++

      group.routes.forEach(route => {
        sidebarArray.push(<CSidebarNavItem key={sidebarIndex} name={route.name} to={route.path} />)
        sidebarIndex++
      })
    })

    return sidebarArray
  }

  return (
    <CSidebar show={sidebarShow}
      onShowChange={() => dispatch(sidebarSlice.actions.toggleMobile())}>
      <CSidebarNav>
        {generateSidebar()}
      </CSidebarNav>
    </CSidebar>
  )
}

function VNCPicker() {
  const dispatch = useDispatch()
  const connectionState = useSelector((state: RootState) => state.vnc.ConnectionState)
  const [endpoint, setEndpoint] = useState("labnuc1")

  const connectButton = () => {
    if (connectionState === "connected" || connectionState === "connecting") {
      return <CButton color="danger" 
        onClick={() => dispatch(VNCSlice.actions.disconnect())}>Disconnect</CButton>
    }

    if (connectionState === "disconnected") {
      return <CButton color="success"
        onClick={() => {
          dispatch(VNCSlice.actions.connect(endpoint))
        }}>Connect</CButton>
    }
  }

  return (
    <div className="border-0 c-subheader-nav m-0 px-0 px-md-3">
      <CSelect style={{ marginRight: "8px" }}
        onChange={(event) => {
          const nuc = (event.target as CSelect).value!.toString()
          setEndpoint(nuc)
        }}>
        <option value="labnuc1">NUC1</option>
        <option value="labnuc2">NUC2</option>
        <option value="labnuc3">NUC3</option>
      </CSelect>
      {connectButton()}
    </div>)
}

function Header() {
  const location = useLocation()
  const dispatch = useDispatch()
  const userFirstName = useSelector((state: RootState) => state.user.currentUser?.firstName)

  return (
    <CHeader withSubheader>
      <CToggler inHeader
        className="ml-md-3 d-lg-none"
        onClick={() => dispatch(sidebarSlice.actions.toggleMobile())}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={() => dispatch(sidebarSlice.actions.toggle())}
      />
      <CHeaderNav className="ml-auto">
        <CHeaderNavItem>
          Welcome {userFirstName}
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <Link to="/settings">
            <CIcon content={cilSettings} size="lg" />
          </Link>
        </CHeaderNavItem>
      </CHeaderNav>
      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes} />
        {location.pathname.includes("vnc") && <VNCPicker />}
      </CSubheader>
    </CHeader>
  )
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function Body() {
  return (
    <Suspense fallback={loading}>
      <Switch>
        {
          routes.map((route, idx) => {
            return (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}>
                <route.component />
              </Route>
            )
          })
        }
      </Switch>
    </Suspense>
  )
}

function MainApp() {
  return (
    <div className="c-app c-default-layout">
      <Sidebar />
      <div className="c-wrapper">
        <Header />
        <Body />
      </div>
    </div>
  )
}

function App() {
  const dispatch = useDispatch()
  const currentUser = useSelector((state: RootState) => state.user.currentUser?.email)

  if (process.env.NODE_ENV === 'production') {
    if (currentUser !== undefined) {
      return <MainApp />
    } else {
      return <Login username={currentUser!} />
    }
  } else {
    dispatch(UserSlice.actions.mockUser())
    return <MainApp />
  }

}

export default App;
