import React from 'react'

const Main = React.lazy(() => import('./Main'))
const MediaList = React.lazy(() => import('./media/MediaList'))
const CultureView = React.lazy(() => import('./cells/CultureView'))
const PrimaryList = React.lazy(() => import('./cells/PrimaryCultureList'))
const StemList = React.lazy(() => import('./cells/StemCultureList'))
const CreateCulture = React.lazy(() => import('./cells/CreateCulture'))
const CreateMedia = React.lazy(() => import('./media/CreateMedia'))
const CircuitDashboard = React.lazy(() => import('./circuit/CircuitDashboard'))
const CreateCircuit = React.lazy(() => import('./circuit/CreateCircuit'))
const MediaChangeSchedule = React.lazy(() => import('./media/MediaChangeSchedule'))
const Project = React.lazy(() => import('./cells/Project'))
const VNC = React.lazy(() => import('./vnc/VNC'))

const mainRoute = [
    {path: '/', name: 'Main', component: Main, exact: true}
]

const createRoutes = [
    { 
        path: '/cultures/primary/create', 
        name: 'Create', 
        component: CreateCulture, 
        exact: true 
    },
    { 
        path: '/cultures/stem/create', 
        name: 'Create', 
        component: CreateCulture, 
        exact: true 
    },
    { 
        path: '/media/create', 
        name: 'Create', 
        component: CreateMedia, 
        exact: true 
    },
    {
        path: '/circuit/create',
        name: 'Create Perfusion Circuit',
        component: CreateCircuit,
        exact: true
    }
]

const cultureSidebarRoutes = [
    { 
        path: '/cultures/stem', 
        name: 'Stem Cell Cultures', 
        component: StemList, 
        exact: true 
    },
    { 
        path: '/cultures/primary', 
        name: 'Primary Cultures', 
        component: PrimaryList, 
        exact: true 
    },
    { 
        path: '/cultures/project', 
        name: 'Projects', 
        component: Project, 
        exact: true 
    }
]

const cultureRoutes = [
    {
        path: '/cultures/stem/:id',
        name: 'Culture View',
        component: CultureView,
        exact: true
    },
    {
        path: '/cultures/primary/:id',
        name: 'Culture View',
        component: CultureView,
        exact: true
    }
]

const mediaRoutes = [
    { 
        path: '/media', 
        name: 'Media Inventory', 
        component: MediaList, 
        exact: true  
    },
    {
        path: '/schedule',
        name: 'Media Change Schedule',
        component: MediaChangeSchedule,
        exact: true
    }
]

const perfusionCircuitRoutes = [
    { 
        path: '/circuit', 
        name: 'Hardware Devices', 
        component: CircuitDashboard, 
        exact: true  
    },
    { 
        path: '/circuit/project', 
        name: 'Projects', 
        component: Project, 
        exact: true  
    }
]

const tools = [
    { 
        path: '/vnc', 
        name: 'VNC Viewer', 
        component: VNC, 
        exact: true  
    }
]

export const sidebarRoutes = [
    { heading: "Neuroscience Research", routes: cultureSidebarRoutes },
    { heading: "Cell Media", routes: mediaRoutes },
    { heading: "Hardware Devices", routes: perfusionCircuitRoutes },
    { heading: "Tools", routes: tools }
]

const routes = mainRoute.concat(cultureSidebarRoutes, createRoutes, cultureRoutes, mediaRoutes, perfusionCircuitRoutes, tools)
export default routes