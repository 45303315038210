import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { sidebarSlice } from '../app/App'
import PrimarySourceSlice from './cells/PrimarySourceSlice'
import StemSourceSlice from './cells/StemSourceSlice'
import ProtocolSlice from './cells/ProtocolSlice'
import CultureSlice from './cells/CultureSlice'
import PrimaryCultureSlice from './cells/PrimaryCultureSlice'
import StemCultureSlice from './cells/StemCultureSlice'
import UserSlice from './UserSlice'
import MediaBatchSlice from './media/BatchSlice'
import MediaFormulaSlice from './media/FormulaSlice'
import MediaAdditiveSlice from './media/AdditiveSlice'
import MediaChangeSlice from './media/MediaChangeSlice'
import PerfusionCircuitSlice from './circuit/PerfusionCircuitSlice'
import VNCSlice from './VNC'

export type RemoteState = 'idle' | 'loading' | 'succeeded' | 'failed'

const rootReducer = combineReducers({
    sidebar: sidebarSlice.reducer,
    user: UserSlice.reducer,
    primarySource: PrimarySourceSlice.reducer,
    stemSource: StemSourceSlice.reducer,
    protocol: ProtocolSlice.reducer,
    culture: CultureSlice.reducer,
    primaryCulture: PrimaryCultureSlice.reducer,
    stemCulture: StemCultureSlice.reducer,
    mediaBatch: MediaBatchSlice.reducer,
    mediaFormula: MediaFormulaSlice.reducer,
    mediaAdditive: MediaAdditiveSlice.reducer,
    mediaChange: MediaChangeSlice.reducer,
    perfusionCircuit: PerfusionCircuitSlice.reducer,
    vnc: VNCSlice.reducer
})

const store = configureStore({
    reducer: rootReducer
})

export default store
export type RootState = ReturnType<typeof rootReducer>