import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authoriseRequest from '../../auth'

const url = process.env.NODE_ENV === 'production' ? 
    'https://labman.corticallabs.com/api/cell/sources/primary' :  `http://${window.location.hostname}:8080/cell/sources/primary`

type RemoteState = 'idle' | 'loading' | 'succeeded' | 'failed'

type PrimaryState = {
    fetchSourceStatus: RemoteState
    createSourceStatus: RemoteState,
    editSourceStatus: RemoteState,
    deleteSourceStatus: RemoteState,
    selectedSource: number,
    sources: PrimarySource[],
}

export type PrimarySource = {
    ID: number
    Reference: string
    Age: number,
    SacrificeDate: string
}

export const fetchPrimarySources = createAsyncThunk('PrimarySource/fetchSource', async () => {
    try {
        const response = await authoriseRequest(url, {
            method: 'GET'
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const createPrimarySource = createAsyncThunk('PrimarySource/createSource', async (source: PrimarySource) => {
    try {
        const response = await authoriseRequest(url, {
            method: 'PUT',
            body: JSON.stringify(source)
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const editPrimarySource = createAsyncThunk('PrimarySource/editSource', async (source: PrimarySource) => {
    try {
        const response = await authoriseRequest(url, {
            method: 'POST',
            body: JSON.stringify(source)
        })
        const newSource = await response.json()
        return newSource
    } catch (error) {
        console.log(error)
    }
    
})

const PrimarySourceSlice = createSlice({
    name: 'PrimarySource',
    initialState: {
        fetchSourceStatus: 'idle',
        createSourceStatus: 'idle',
        editSourceStatus: 'idle',
        deleteSourceStatus: 'idle',
        selectedSource: 0,
        sources: []
    } as PrimaryState,
    reducers: {
        addSource(state, action) {
            state.sources.push(action.payload)
        },
        setSource(state, action) {
            state.selectedSource = action.payload
        },
        resetSelectedSource(state) {
            state.selectedSource = 0
        }
    },
    extraReducers: builder => {
        // Fetch (GET) sources
        builder.addCase(fetchPrimarySources.pending, (state) => {
            state.fetchSourceStatus = 'loading'
        })
        builder.addCase(fetchPrimarySources.fulfilled, (state, action) => {
            state.fetchSourceStatus = 'succeeded'
            
            if (action.payload !== null) {
                console.log(action.payload)
                action.payload.forEach((element: PrimarySource) => {
                    element.SacrificeDate = convertDateString(element.SacrificeDate)
                    state.sources.push(element)
                })
            }
        })
        builder.addCase(fetchPrimarySources.rejected, (state) => {
            state.fetchSourceStatus = 'failed'
        })
        // Create (PUT) source
        builder.addCase(createPrimarySource.pending, (state) => {
            state.createSourceStatus = 'loading'
        })
        builder.addCase(createPrimarySource.fulfilled, (state, action) => {
            state.createSourceStatus = 'succeeded'
            let newSource = action.payload as PrimarySource
            newSource.SacrificeDate = convertDateString(newSource.SacrificeDate)
            state.sources.push(newSource)
            state.selectedSource = state.sources.length
        })
        builder.addCase(createPrimarySource.rejected, (state) => {
            state.createSourceStatus = 'failed'
        })
        // Edit (POST) source
        builder.addCase(editPrimarySource.pending, (state) => {
            state.editSourceStatus = 'loading'
        })
        builder.addCase(editPrimarySource.fulfilled, (state,action) => {
            state.editSourceStatus = 'succeeded'
            let editedSource = action.payload as PrimarySource
            for (let index = 0; index < state.sources.length; index++) {
                const element = state.sources[index];
                if (element.ID === editedSource.ID) {
                    state.sources.splice(index, 1, editedSource)
                    break
                }
            }
        })
        builder.addCase(editPrimarySource.rejected, (state) => {
            state.editSourceStatus = 'failed'
        })
    }
})

function convertDateString(dateString: string): string {
    let date = new Date(dateString)
    return date.toISOString().split('T')[0]
}

export const {addSource, setSource} = PrimarySourceSlice.actions

export default PrimarySourceSlice