import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import * as msal from "@azure/msal-browser"
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const userValidateURL = "https://labman.corticallabs.com/api/user/validate"
const redirectUri = 'https://labman.corticallabs.com'

const msalConfig = {
    auth: {
        clientId: "63be670c-e1ff-4ee1-abaf-c1c54499159b",
        authority: "https://login.microsoftonline.com/fecd21f3-5c48-4582-a829-a6b48c37a793",
        redirectUri: redirectUri
    }
}

const msalInstance = new msal.PublicClientApplication(msalConfig)

type UserState = {
    token: string | undefined,
    currentUser: CurrentUser | undefined
}

type CurrentUser = {
    id: number
    userId: string
    firstName: string
    lastName: string
    email: string
}

interface IDToken {
    oid: string
    exp: number
    family_name: string
    given_name: string
    email: string
}

async function foo(tokenResponse: msal.AuthenticationResult) {
    const idToken = tokenResponse.idTokenClaims as IDToken
 
    if (idToken.exp > Math.floor(Date.now() /1000)) {
        const idToken = tokenResponse.idToken
        const validateUser = await fetch(userValidateURL, {
            method: 'POST',
            body: idToken.toString()
        })

        const json = await validateUser.json()
        json.idToken = idToken
        return json
    } else {
        return new Error("Token expired")
    }
}

export const authenticate = createAsyncThunk('authenticate', async (username: string | undefined) => {
    if (username !== undefined) {
        try {
            const loginResponse = await msalInstance.ssoSilent({
                scopes: ["user.read", "email", "profile", "openid"],
                loginHint: username,
                redirectUri: redirectUri
            })
            const bar = await foo(loginResponse)
            return bar
        } catch (err) {
            console.log(err)
            if (err instanceof InteractionRequiredAuthError) {
                const tokenResponse = await msalInstance.handleRedirectPromise()
                if (tokenResponse !== null) {
                    const bar = await foo(tokenResponse)
                    return bar
                } else {
                    msalInstance.loginRedirect()
                }
            }
        }
    } else {
        const tokenResponse = await msalInstance.handleRedirectPromise()
        if (tokenResponse !== null) {
            const bar = await foo(tokenResponse)
            return bar
        } else {
            msalInstance.loginRedirect()
        }
    }
    
})

const UserSlice = createSlice({
    name: "User",
    initialState: {
        token: undefined,
        currentUser: undefined
    } as UserState,
    reducers: {
        mockUser: (state) => {
            state.currentUser = {
                id: 1,
                email: "john.doe@cclabs.ai",
                firstName: "John",
                lastName: "Doe",
                userId: "1"
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(authenticate.pending, (state) => {
            console.log('Logging in')
        })
        builder.addCase(authenticate.fulfilled, (state, action) => {
            console.log("Logged In")
            state.currentUser = {
                id: action.payload.id,
                userId: action.payload.uuid,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email
            }
            state.token = action.payload.idToken
        })
        builder.addCase(authenticate.rejected, (state) => {
            console.log("Failed to log in")
        })
    }
})

export default UserSlice