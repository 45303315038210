import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authoriseRequest from "../../auth";

const url = process.env.NODE_ENV === 'production' ? 
    'https://labman.corticallabs.com/api/media/batches' :  `http://${window.location.hostname}:8080/media/batches`

type RemoteState = 'idle' | 'loading' | 'succeeded' | 'failed' 

export type MediaBatch = {
    ID: number, 
    FormulaName: string,
    BatchNumber: number,
    PreparationTime: string,
    UUID: string,
    Used: boolean,
    Additives: string[],
    User: {
        ID: number,
        Name: string,
    }
}

export type CreateMediaBatch = {
    UUID: string,
    BatchNumber: number,
    FormulaID: number,
    UserID: number,
    Additives: number[],
    PreparationTime: string
}

type MediaBatchState = {
    fetchBatchesState: RemoteState,
    createMediaBatchState: RemoteState,
    selectedBatch: number,
    mediaBatches: MediaBatch[]
}

export const fetchMediaBatches = createAsyncThunk("Media/fetchBatches", async () => {
    try {
        const response =  await authoriseRequest(url, {
            method: 'GET'
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const createMediaBatch = createAsyncThunk("Media/createBatch", 
    async (payload: CreateMediaBatch) => {
        try {
            const response =  await authoriseRequest(url, {
                method: 'PUT',
                body: JSON.stringify(payload)
            })
            return response
        } catch (error) {
            console.log(error)
        }
    }
)


const MediaBatchSlice = createSlice({
    name: "MediaBatch",
    initialState: {
        fetchBatchesState: 'idle',
        createMediaBatchState: 'idle',
        selectedBatch: 0,
        mediaBatches: []
    } as MediaBatchState,
    reducers: {
        setBatch: (state, action) => {
            state.selectedBatch = action.payload
        },
        resetCreateState: (state) => {
            state.createMediaBatchState = 'idle'
        }
    },
    extraReducers: builder => {
        builder.addCase(createMediaBatch.pending, (state) => {
            state.createMediaBatchState = 'loading'
        })
        builder.addCase(createMediaBatch.fulfilled, (state, action) => {
            state.createMediaBatchState = 'succeeded'
            console.log(action.payload)
            state.mediaBatches.push(action.payload)
        })
        builder.addCase(fetchMediaBatches.pending, (state) => {
            state.fetchBatchesState = 'loading'
        })
        builder.addCase(fetchMediaBatches.fulfilled, (state, action) => {
            state.fetchBatchesState = 'succeeded'
            state.mediaBatches = action.payload
        })
    }
})

export default MediaBatchSlice