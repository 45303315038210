import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import authoriseRequest from "../../auth"

const url = process.env.NODE_ENV === 'production' ? 
    'https://labman.corticallabs.com/api/cell/protocols' :  `http://${window.location.hostname}:8080/cell/protocols`

type RemoteState = 'idle' | 'loading' | 'succeeded' | 'failed'

type ProtocolState = {
    fetchProtocolStatus: RemoteState
    createProtocolStatus: RemoteState,
    editProtocolStatus: RemoteState,
    deleteProtocolStatus: RemoteState,
    selectedProtocol: number,
    protocols: Protocol[]
}

export type Protocol = {
    ID: number,
    Name: string,
    Description: string
    Type: string
}

export const fetchProtocols = createAsyncThunk('PrimaryProtocol/fetchProtocol', async () => {
    try {
        const response = await authoriseRequest(url, {
            method: 'GET'
        })

        return response
    } catch (error) {
        console.log(error)
    }
})

export const createProtocol = createAsyncThunk('PrimaryProtocol/createProtocol', async(protocol: Protocol) => {
    try {
        const response = await authoriseRequest(url, {
            method: 'PUT',
            body: JSON.stringify(protocol)
        })

        return response
    } catch (error) {
        console.log(error)
    }
})

export const editProtocol = createAsyncThunk('PrimaryProtocol/editProtocol', async (protocol: Protocol) => {
    try {
        const response = await authoriseRequest(url, {
            method: 'POST',
            body: JSON.stringify(protocol)
        })

        return response
    } catch (error) {
        console.log(error)
    }
})

export const deleteProtocol = createAsyncThunk('PrimaryProtocol/deleteProtocol', async (id: number) => {

})

const ProtocolSlice = createSlice({
    name: 'Protocol',
    initialState: {
        fetchProtocolStatus: 'idle',
        createProtocolStatus: 'idle',
        editProtocolStatus: 'idle',
        deleteProtocolStatus: 'idle',
        selectedProtocol: 0,
        protocols: []
    } as ProtocolState,
    reducers: {
        setProtocol(state, action) {
            state.selectedProtocol = action.payload
        },
        resetSelectedProtocol(state) {
            state.selectedProtocol = 0
        }
    },
    extraReducers: builder => {
        // Fetch (GET) protocols
        builder.addCase(fetchProtocols.pending, (state) => {
            state.fetchProtocolStatus = 'loading'
        })
        builder.addCase(fetchProtocols.fulfilled, (state, action) => {
            state.fetchProtocolStatus = 'succeeded'
            
            if (action.payload !== null) {
                action.payload.forEach((element: Protocol) => {
                    state.protocols.push(element)
                })
            }
        })
        builder.addCase(fetchProtocols.rejected, (state) => {
            state.fetchProtocolStatus = 'failed'
        })
        // Add (PUT) protocol
        builder.addCase(createProtocol.pending, (state) => {
            state.createProtocolStatus = 'loading'
        })
        builder.addCase(createProtocol.fulfilled, (state, action) => {
            state.createProtocolStatus = 'succeeded'
            state.protocols.push(action.payload as Protocol)
            state.selectedProtocol = state.protocols.length
        })
        builder.addCase(createProtocol.rejected, (state, action) => {
            state.createProtocolStatus = 'failed'
        })
        // Edit (POST) protocol
        builder.addCase(editProtocol.pending, (state) => {
            state.editProtocolStatus = 'loading'
        })
        builder.addCase(editProtocol.fulfilled, (state, action) => {
            state.editProtocolStatus = 'succeeded'
            let editedProtocol = action.payload as Protocol
            for (let index = 0; index < state.protocols.length; index++) {
                const element = state.protocols[index];
                if (element.ID === editedProtocol.ID) {
                    state.protocols.splice(index, 1, editedProtocol)
                    break
                }
            }
        })
    }
})

export const {setProtocol} = ProtocolSlice.actions

export default ProtocolSlice