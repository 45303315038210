import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authoriseRequest from "../../auth";
import { CreateCulturePayload, Culture } from "./CultureSlice";
import { Protocol } from "./ProtocolSlice";
import { StemSource } from "./StemSourceSlice";

const url = process.env.NODE_ENV === 'production' ? 
    'https://labman.corticallabs.com/api/cell/cultures/stem/' :  `http://${window.location.hostname}:8080/cell/cultures/stem/`

type RemoteState = 'idle' | 'loading' | 'succeeded' | 'failed'

export interface StemCulture extends Culture {
    ID: number,
    UUID: string,
    MeaID: number,
    Inception: string,
    LastMediaChange: string,
    Density: number,
    AraC: boolean,
    Death: string,
    Comment: string,
    Protocol: Protocol,
    Name: string,
    Source: StemSource
}

export const fetchStemCultureList = createAsyncThunk("StemCulture/getCultureList", async () => {
    try {
        const response = authoriseRequest(url, {
            method: 'GET'
        })
        return response        
    } catch (error) {
        console.log(error)
    }
})

export const fetchStemCulture = createAsyncThunk("StemCulture/getCulture",  async(id: number) => {
    try {
        const response = authoriseRequest(url+`${id}`, {
            method: 'GET'
        })

        return response        
    } catch (error) {
        console.log(error)
    }
})

export const markStemCultureDead = createAsyncThunk("StemCulture/markDead", async (id: number) => {
    try {
        const response = authoriseRequest(url, {
            method: 'POST',
            body: JSON.stringify({'CMD': 'markDead', 'CultureID': id})
        }, true)

        return response
    } catch (error) {
        console.log(error)
    }
})

type StemCultureState = {
    fetchCultureListState: RemoteState,
    fetchCultureState: RemoteState,
    editCultureState: RemoteState,
    deleteCulturState: RemoteState,
    markCultureDead: RemoteState,
    cultures: StemCulture[]
}

const StemCultureSlice = createSlice({
    name: "StemCulture",
    initialState: {
        fetchCultureListState: 'idle',
        fetchCultureState: 'idle',
        editCultureState: 'idle',
        deleteCulturState: 'idle',
        markCultureDead: 'idle',
        cultures: []
    } as StemCultureState,
    reducers: {
        resetFetchCultureState: (state) => {
            state.fetchCultureState = 'idle'
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchStemCulture.pending, (state) => {
            state.fetchCultureState = 'loading'
        })
        builder.addCase(fetchStemCulture.fulfilled, (state, action) => {
            const culture = action.payload[0]
            state.cultures.push(culture)
            state.fetchCultureState = 'succeeded'
        })
        builder.addCase(fetchStemCultureList.pending, (state) => {
            state.fetchCultureListState = 'loading'
        })
        builder.addCase(fetchStemCultureList.fulfilled, (state, action) => {
            state.fetchCultureListState = 'succeeded'
            state.cultures = action.payload
        })
        builder.addCase(fetchStemCultureList.rejected, (state,action) => {
            state.fetchCultureListState = 'failed'
            console.log(action.error)
        })
        builder.addCase(markStemCultureDead.pending, (state) => {
            state.markCultureDead = 'loading'
        })
        builder.addCase(markStemCultureDead.fulfilled, (state, action) => {
            state.markCultureDead = 'succeeded'
            const cultureID = action.payload.CultureID
            const deathTime = action.payload.DeathTime

            const culture = state.cultures.find(culture => culture.ID == cultureID)
            if (culture !== undefined) {
                culture.Death = deathTime
            }
            
            console.log(action.payload)
        })
        builder.addCase(markStemCultureDead.rejected, (state, action) => {
            state.markCultureDead = 'failed'
            console.log(action.error)
        })
    }
})

export default StemCultureSlice