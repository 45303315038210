import { createSlice } from "@reduxjs/toolkit";

type VNCState = {
    EndPoint: "labnuc1" | "labnuc2" | "labnuc3" | undefined,
    ConnectionState: "connecting" | "connected" | "disconnected"
}

const VNCSlice = createSlice({
    name: "VNC",
    initialState: {
        EndPoint: undefined,
        ConnectionState: "disconnected"
    } as VNCState,
    reducers: {
        connect: (state, action) => {
            const nuc = action.payload
            state.ConnectionState = "connecting"
            state.EndPoint = nuc
        },
        setConnected: (state) => {
            state.ConnectionState = "connected"
        },
        disconnect: (state) => {
            state.ConnectionState = "disconnected"
        }
    }
})

export default VNCSlice