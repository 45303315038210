import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import authoriseRequest from "../../auth";
import { RemoteState } from "../rootReducer";

const baseURL = process.env.NODE_ENV === 'production' ? 
    'https://labman.corticallabs.com/api/circuit/' :  `http://${window.location.hostname}:8080/circuit/`

type PerfusionCircuitState = {
    createPerfusionCircuitState: RemoteState,
    getReadingsState: RemoteState,
    getSetpointsState: RemoteState,
    getListState: RemoteState,
    setCircuitSetpointState: RemoteState,
    circuits: {
        id: number,
        mac: string,
        ip: string,
        readings: PerfusionCircuitReadingReturn[],
        setpoints: PerfusionCircuitSetpointReturn[]
    }[]
}

export type PerfusionCircuitCreateSetpoint = {
    User: number,
    PerfusionCircuit: number,
    Time: string,
    Temp: number,
    Flow: number,
    Flow2: number,
    CO2: number,
    O2: number
}

export type PerfusionCircuitReadingReturn = {
    ID: number,
    PerfusionCircuit: number,
    User: string,
    Time: string,
    Temp: number,
    Flow: number,
    Flow2: number,
    CO2: number,
    O2: number,
    PH: number,
    PH2: number,
    DO2: number,
    DO22: number
}

export type PerfusionCircuitSetpointReturn = {
    ID: number,
    PerfusionCircuit: number,
    User: string,
    Time: string,
    Temp: number,
    Flow: number,
    Flow2: number,
    CO2: number,
    O2: number
}

export type PCReadingsPayload = {
    circuitMac: string, 
    start: string, 
    end: string
}

type PerfusionCircuitReturnPayload = {
    ID: number,
    Mac: string,
    IP: string
}

export const createPerfusionCircuit = createAsyncThunk('PerfusionCircuit/createCircuit', async(mac: string) => {
    try {
        const response = await authoriseRequest(baseURL, {
            method: 'PUT',
            body: JSON.stringify({
                ID: 0,
                Mac: mac,
                IP: ""
            })
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const getPerfusionCircuitList = createAsyncThunk('PerfusionCircuit/getCircuitList', async() => {
    try {
        const response = await authoriseRequest(baseURL, {
            method: "GET"
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const getPerfusionCircuitReadings = createAsyncThunk('PerfusionCircuit/getCircuitReadings', async(payload: PCReadingsPayload) => {
    let url: string

    if (payload.start !== "" && payload.end !== "") {
        url = baseURL + `readings/${payload.circuitMac}?start=${payload.start}&end=${payload.end}`
    } else {
        url = baseURL + `readings/${payload.circuitMac}`
    }
    
    try {
        const response = await authoriseRequest(url, {
            method: "GET"
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const getPerfusionCircuitSetpoint = createAsyncThunk('PerfusionCircuit/getCircuitSetpoint', async(payload: PCReadingsPayload) => {
    let url: string

    if (payload.start !== "" && payload.end !== "") {
        url = baseURL + `setpoints/${payload.circuitMac}?start=${payload.start}&end=${payload.end}`
    } else {
        url = baseURL + `setpoints/${payload.circuitMac}`
    }
    
    try {
        const response = await authoriseRequest(url, {
            method: "GET"
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const setPerfusionCircuitSetpoint = createAsyncThunk('PerfusionCircuit/setCircuitSetpoint', 
    async(payload: {circuitMac: string, data: PerfusionCircuitCreateSetpoint}) => {

        const url = baseURL + `setpoints/${payload.circuitMac}`

        try {
            const response = await authoriseRequest(url, {
                method: "PUT",
                body: JSON.stringify(payload.data)
            })
            return response
        } catch (error) {
            console.log(error)
        }
    })

export const getReadingsCSV = createAsyncThunk('PerfusionCircuit/getReadingsCSV', 
    async (circuitMac: string) => 
{
    let url: string
    url = baseURL + `readings/${circuitMac}`
    
    try {
        const response = await authoriseRequest(url, {
            method: "GET"
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const getSetpointsCSV = createAsyncThunk("PerfusionCircuit/getSetpointsCSV", 
    async(circuitMac: string) => 
{
    let url: string
    url = baseURL + `setpoints/${circuitMac}`
    
    try {
        const response = await authoriseRequest(url, {
            method: "GET"
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

const PerfusionCircuitSlice = createSlice({
    name: "PerfusionCircuit",
    initialState: {
        createPerfusionCircuitState: 'idle',
        getReadingsState: 'idle',
        getSetpointsState: 'idle',
        getListState: 'idle',
        setCircuitSetpointState: 'idle',
        circuits: []
    } as PerfusionCircuitState,
    reducers: {
        resetSetCircuitSetpointState(state) {
            state.setCircuitSetpointState = 'idle'
        },
        resetGetCircuitSetpointsState(state) {
            state.getSetpointsState = 'idle'
        },
        resetCircuitReadingState(state) {
            state.getReadingsState = 'idle'
        }
    },
    extraReducers: builder => {
        builder.addCase(getReadingsCSV.fulfilled, (state, action) => {
            const readings = action.payload as PerfusionCircuitReadingReturn[]
            // console.log(readings)

            let csv = 'ID,Time,Temp,CO2,O2,DO2,DO22,PH,PH2,Flow,Flow2\n'
            readings.sort((a,b) => a.ID - b.ID).forEach(reading => {
                csv += `${reading.ID},${reading.Time},${reading.Temp},${reading.CO2},${reading.O2},${reading.DO2},${reading.DO22},${reading.PH},${reading.PH2},${reading.Flow},${reading.Flow2}\n`
            })

            const data = new Blob([csv], {type: 'text/csv'})
            const readingsCSVURL = window.URL.createObjectURL(data)
            const tempLink = document.createElement('a')
            tempLink.href = readingsCSVURL
            tempLink.setAttribute('download', 'readings.csv')
            tempLink.click()
        })
        builder.addCase(getSetpointsCSV.fulfilled, (state, action) => {
            const setpoints = action.payload as PerfusionCircuitSetpointReturn[]
            console.log(setpoints)
            
            let csv = 'ID,Time,Temp,CO2,O2,Flow,Flow2\n'
            setpoints.sort((a,b) => a.ID - b.ID).forEach(setpoint => {
                csv += `${setpoint.ID},${setpoint.Time},${setpoint.Temp},${setpoint.CO2},${setpoint.O2},${setpoint.Flow},${setpoint.Flow2}\n`
            })

            const data = new Blob([csv], {type: 'text/csv'})
            const setpointsCSVURL = window.URL.createObjectURL(data)
            const tempLink = document.createElement('a')

            tempLink.href = setpointsCSVURL
            tempLink.setAttribute('download', 'setpoints.csv')
            tempLink.click()
        })
        builder.addCase(createPerfusionCircuit.pending, (state) => {
            state.createPerfusionCircuitState = 'loading'
        })
        builder.addCase(createPerfusionCircuit.fulfilled, (state, action) => {
            state.createPerfusionCircuitState = 'succeeded'
            
            const PerfusionCircuit = action.payload as PerfusionCircuitReturnPayload

            const circuit = {
                id: PerfusionCircuit.ID,
                mac: PerfusionCircuit.Mac,
                ip: PerfusionCircuit.IP,
                readings: [],
                setpoints: []
            }
            state.circuits.push(circuit)
        })
        builder.addCase(createPerfusionCircuit.rejected, (state, action) => {
            state.createPerfusionCircuitState = 'failed'
            console.log(action.payload)
        })
        builder.addCase(getPerfusionCircuitReadings.pending, (state) => {
            state.getReadingsState = 'loading'
        })
        builder.addCase(getPerfusionCircuitReadings.fulfilled, (state, action) => {
            state.getReadingsState = 'succeeded'
            const readings = action.payload as PerfusionCircuitReadingReturn[]
            if (readings.length > 0) {
                const circuit = state.circuits.find(circuit => circuit.id === readings[0].PerfusionCircuit)
                
                if (circuit !== undefined) {
                    circuit.readings = readings.sort((a,b) => b.ID - a.ID)
                }
            }
        })
        builder.addCase(getPerfusionCircuitReadings.rejected, (state, action) => {
            state.getReadingsState = 'failed'
            console.log(action.payload)
        })
        builder.addCase(getPerfusionCircuitSetpoint.pending, (state) => {
            state.getSetpointsState = 'loading'
        })
        builder.addCase(getPerfusionCircuitSetpoint.fulfilled, (state, action) => {
            state.getSetpointsState = 'succeeded'
            const setpoints = action.payload as PerfusionCircuitSetpointReturn[]
            if (setpoints.length > 0) {
                const circuit = state.circuits.find(circuit => circuit.id === setpoints[0].PerfusionCircuit)
                
                if (circuit !== undefined) {
                    circuit.setpoints = setpoints
                }
            }
        })
        builder.addCase(getPerfusionCircuitSetpoint.rejected, (state, action) => {
            state.getSetpointsState = 'failed'
        })
        builder.addCase(getPerfusionCircuitList.pending, (state) => {
            state.getListState = 'idle'
        })
        builder.addCase(getPerfusionCircuitList.fulfilled, (state, action) => {
            state.getListState = 'succeeded'
            const returnPayload = action.payload as PerfusionCircuitReturnPayload[]
            
            returnPayload.forEach(PerfusionCircuit => {
                if (state.circuits.find((circuit) => circuit.mac === PerfusionCircuit.Mac) === undefined) {
                    const circuit = {
                        id: PerfusionCircuit.ID,
                        mac: PerfusionCircuit.Mac,
                        ip: PerfusionCircuit.IP,
                        readings: [],
                        setpoints: []
                    }
                    state.circuits.push(circuit)
                }
            })
        })
        builder.addCase(getPerfusionCircuitList.rejected, (state, action) => {
            state.getListState = 'failed'
        })
        builder.addCase(setPerfusionCircuitSetpoint.pending, (state) => {
            state.setCircuitSetpointState = 'loading'
        })
        builder.addCase(setPerfusionCircuitSetpoint.fulfilled, (state, action) => {
            state.setCircuitSetpointState = 'succeeded'
            const returnPayload = action.payload as PerfusionCircuitSetpointReturn
            const circuit = state.circuits.find(circuit => circuit.id === returnPayload.PerfusionCircuit)
            
            if (circuit !== undefined) {
                circuit.setpoints[0] = returnPayload
            }
            
        })
    }
})

export default PerfusionCircuitSlice