import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { create } from "domain";
import authoriseRequest from "../../auth";

const url = process.env.NODE_ENV === 'production' ? 
    'https://labman.corticallabs.com/api/media/changes' :  `http://${window.location.hostname}:8080/media/changes`

export type MediaChangePayload = {
    CultureID: number,
    Datetime: string,
    UserID: number,
    BatchID: number
}

type RemoteState = 'idle' | 'loading' | 'succeeded' | 'failed'

type MediaChangeReturnPayload = {
    ID: number,
    CultureID: number,
    CultureType: string,
    CultureBatch: string,
    BatchID: number,
    Datetime: string,
    Username: string,
    Formula: string
}

type MediaChangeState = {
    createMediaChangeState: RemoteState,
    fetchMediaChangeState: RemoteState,
    changes: {
        CultureID: number,
        MediaChanges: MediaChangeReturnPayload[]
    }[]
}

export const fetchMediaChanges =  createAsyncThunk("Media/fetchMediaChanges", async (cultureID: number) => {
    try {
        const queryURL = url + `?id=${cultureID}`
        const response = await authoriseRequest(queryURL, {
            method: 'GET'
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const createMediaChange = createAsyncThunk("Media/createMediaChange", async (payload: MediaChangePayload[]) => {
    try {
        const response = await authoriseRequest(url, {
            method: 'PUT',
            body: JSON.stringify(payload)
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

const MediaChangeSlice = createSlice({
    name: "PrimaryMediaChange",
    initialState: {
        createMediaChangeState: 'idle',
        fetchMediaChangeState: 'idle',
        changes: []
    } as MediaChangeState,
    reducers: {
        resetCreateState: (state) => {
            state.createMediaChangeState = 'idle'
        },
        resetFetchState: (state) => {
            state.fetchMediaChangeState = 'idle'
        }
    },
    extraReducers: builder => {
        builder.addCase(createMediaChange.pending, (state) => {
            state.createMediaChangeState = 'loading'
        })
        builder.addCase(createMediaChange.fulfilled, (state, action) => {
            state.createMediaChangeState = 'succeeded'

            action.payload.forEach((payload: MediaChangeReturnPayload) => {
                const cultureID = payload.CultureID
                console.log(`Culture ID - ${cultureID}`)
                const culture =  state.changes.find(change => change.CultureID === cultureID)
                
                if (culture === undefined) {
                    const newCulture = {
                        CultureID: cultureID,
                        MediaChanges: [payload]
                    }
                    state.changes.unshift(newCulture)
                } else {
                    console.log(payload)
                    culture.MediaChanges.unshift(payload)
                }
            });
            

        })
        builder.addCase(fetchMediaChanges.pending, (state) => {
            state.fetchMediaChangeState = 'loading'
        })
        builder.addCase(fetchMediaChanges.fulfilled, (state, action) => {
            state.fetchMediaChangeState = 'succeeded'
            const fetchPayload = action.payload as MediaChangeReturnPayload[]

            if (fetchPayload.length > 0) {
                const cultureID = fetchPayload[0].CultureID
                const culture =  state.changes.find(change => change.CultureID === cultureID)
                
                if (culture === undefined) {
                    const newCulture = {
                        CultureID: cultureID,
                        MediaChanges: fetchPayload
                    }
                    state.changes.unshift(newCulture)
                } else {
                    culture.MediaChanges = fetchPayload
                }
            }
        })
    }
})

export default MediaChangeSlice