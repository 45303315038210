import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authoriseRequest from '../../auth'

const url = process.env.NODE_ENV === 'production' ? 
    'https://labman.corticallabs.com/api/cell/sources/stem' :  `http://${window.location.hostname}:8080/cell/sources/stem`

type RemoteState = 'idle' | 'loading' | 'succeeded' | 'failed'

type StemState = {
    fetchSourceStatus: RemoteState
    createSourceStatus: RemoteState,
    editSourceStatus: RemoteState,
    deleteSourceStatus: RemoteState,
    selectedSource: number,
    sources: StemSource[],
}

export type StemSource = {
    ID: number
    Name: string
    PassageNumber: number,
    NeuralInduction: string
}

export const fetchStemSources = createAsyncThunk('StemSource/fetchSource', async () => {
    try {
        const response = await authoriseRequest(url, {
            method: 'GET'
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const createStemSource = createAsyncThunk('StemSource/createSource', async (source: StemSource) => {
    try {
        const response = await authoriseRequest(url, {
            method: 'PUT',
            body: JSON.stringify(source)
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const editStemSource = createAsyncThunk('StemSource/editSource', async (source: StemSource) => {
    try {
        const response = await authoriseRequest(url, {
            method: 'POST',
            body: JSON.stringify(source)
        })
        const newSource = await response.json()
        return newSource
    } catch (error) {
        console.log(error)
    }
    
})

const StemSourceSlice = createSlice({
    name: 'StemSource',
    initialState: {
        fetchSourceStatus: 'idle',
        createSourceStatus: 'idle',
        editSourceStatus: 'idle',
        deleteSourceStatus: 'idle',
        selectedSource: 0,
        sources: []
    } as StemState,
    reducers: {
        addSource(state, action) {
            state.sources.push(action.payload)
        },
        setSource(state, action) {
            state.selectedSource = action.payload
        },
        resetSelectedSource(state) {
            state.selectedSource = 0
        }
    },
    extraReducers: builder => {
        // Fetch (GET) sources
        builder.addCase(fetchStemSources.pending, (state) => {
            state.fetchSourceStatus = 'loading'
        })
        builder.addCase(fetchStemSources.fulfilled, (state, action) => {
            state.fetchSourceStatus = 'succeeded'
            
            if (action.payload !== null) {
                console.log(action.payload)
                action.payload.forEach((element: StemSource) => {
                    element.NeuralInduction = convertDateString(element.NeuralInduction)
                    state.sources.push(element)
                })
            }
        })
        builder.addCase(fetchStemSources.rejected, (state) => {
            state.fetchSourceStatus = 'failed'
        })
        // Create (PUT) source
        builder.addCase(createStemSource.pending, (state) => {
            state.createSourceStatus = 'loading'
        })
        builder.addCase(createStemSource.fulfilled, (state, action) => {
            state.createSourceStatus = 'succeeded'
            let newSource = action.payload as StemSource
            newSource.NeuralInduction = convertDateString(newSource.NeuralInduction)
            state.sources.push(newSource)
            state.selectedSource = state.sources.length
        })
        builder.addCase(createStemSource.rejected, (state) => {
            state.createSourceStatus = 'failed'
        })
        // Edit (POST) source
        builder.addCase(editStemSource.pending, (state) => {
            state.editSourceStatus = 'loading'
        })
        builder.addCase(editStemSource.fulfilled, (state,action) => {
            state.editSourceStatus = 'succeeded'
            let editedSource = action.payload as StemSource
            for (let index = 0; index < state.sources.length; index++) {
                const element = state.sources[index];
                if (element.ID === editedSource.ID) {
                    state.sources.splice(index, 1, editedSource)
                    break
                }
            }
        })
        builder.addCase(editStemSource.rejected, (state) => {
            state.editSourceStatus = 'failed'
        })
    }
})

function convertDateString(dateString: string): string {
    let date = new Date(dateString)
    return date.toISOString().split('T')[0]
}

export const {addSource, setSource} = StemSourceSlice.actions

export default StemSourceSlice