import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import authoriseRequest from "../../auth"

const url = process.env.NODE_ENV === 'production' ? 
    'https://labman.corticallabs.com/api/media/additives' :  `http://${window.location.hostname}:8080/media/additives`

type RemoteState = 'idle' | 'loading' | 'succeeded' | 'failed' 

export type MediaAdditive = {
    ID: number,
    Name: string,
    Description: string,
}

type AdditiveState = {
    createAdditiveState: RemoteState,
    fetchAdditivesState: RemoteState
    additives: MediaAdditive[],
    selectedAdditives: number[]
}

export const createAdditives = createAsyncThunk("Media/createAdditive", async (payload: MediaAdditive) => {
    try {
        const response = await authoriseRequest(url, {
            method: 'PUT',
            body: JSON.stringify(payload)
        })
        return response
    } catch (error)  {
        console.log(error)
    }
})

export const fetchAdditives = createAsyncThunk("Media/getAdditives", async () => {
    try {
        const response = await authoriseRequest(url, {
            method: 'GET'
        })
        return response
    } catch (error) {
        console.log(error)
    }
    
})

const AdditiveSlice = createSlice({
    name: "MediaAdditive",
    initialState: {
        createAdditiveState: 'idle',
        fetchAdditivesState: 'idle',
        additives: [],
        selectedAdditives: []
    } as AdditiveState,
    reducers: {
        addSelectedAdditive: (state, action) => {
            state.selectedAdditives.push(action.payload)
        },
        removeSelectedAdditive: (state, action) => {
            const id = action.payload.id
            state.selectedAdditives = state.selectedAdditives.filter(additiveID => additiveID !== id)
        },
        resetCreateAdditiveState: (state) => {
            state.createAdditiveState = 'idle'
        },
        resetSelectedAdditives: (state) => {
            state.additives = []
        }
    },
    extraReducers: builder => {
        builder.addCase(createAdditives.fulfilled, (state, action) => {
            state.createAdditiveState = 'succeeded'
            state.additives.push(action.payload)
            state.selectedAdditives.push(action.payload.ID)
        })
        builder.addCase(fetchAdditives.pending, (state) => {
            state.fetchAdditivesState = 'loading'
        })
        builder.addCase(fetchAdditives.fulfilled, (state, action) => {
            state.fetchAdditivesState = 'succeeded'
            if (action.payload !== null) {
                state.additives = action.payload
            }
        })
    }
})

export default AdditiveSlice