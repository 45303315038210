import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import authoriseRequest from "../../auth"
import { RemoteState } from "../rootReducer";

const url = process.env.NODE_ENV === 'production' ? 
    'https://labman.corticallabs.com/api/cell/cultures/' :  `http://${window.location.hostname}:8080/cell/cultures/`

type SourceType = 'p' | 's' // Primary/Stem

export interface Culture {
    ID: number,
    UUID: string,
    MeaID: number,
    Inception: string,
    LastMediaChange: string,
    SourceType: SourceType,
    Density: number,
    AraC: boolean,
    Death: string,
    Comment: string
}

export type CreateCulturePayload = {
    UUID: string,
    MeaID: number,
    ProtocolID: number,
    SourceID: number, 
    AraC: boolean,
    Density: number,
    Inception: string,
    UserID: number,
    Comment: string
}

export const createCulture = createAsyncThunk("Culture/createCulture", async (payload: CreateCulturePayload) => {
    try {
        const response =  await authoriseRequest(url, {
            method: 'PUT',
            body: JSON.stringify(payload)
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const fetchCultureList = createAsyncThunk("Culture/getCultureList", async () => {
    try {
        const response = authoriseRequest(url, {
            method: 'GET'
        })

        return response        
    } catch (error) {
        console.log(error)
    }
})

type CultureState = {
    fetchCultureListState: RemoteState,
    createCultureState: RemoteState,
    editCultureState: RemoteState,
    deleteCultureState: RemoteState,
    cultures: Culture[]
}

const CultureSlice = createSlice({
    name: "Culture",
    initialState: {
        fetchCultureListState: 'idle',
        createCultureState: 'idle',
        editCultureState: 'idle',
        deleteCultureState: 'idle',
        cultures: []
    } as CultureState,
    reducers: {
        resetCreateState: (state) => {
            state.createCultureState = 'idle'
        }
    },
    extraReducers: builder => {
        builder.addCase(createCulture.fulfilled, (state, action) => {
            state.createCultureState = 'succeeded'
            console.log(action.payload)
            state.cultures.push(action.payload)
        })
        builder.addCase(createCulture.pending, (state) => {
            state.createCultureState = 'loading'
        })
        builder.addCase(fetchCultureList.pending, (state) => {
            state.fetchCultureListState = 'loading'
        })
        builder.addCase(fetchCultureList.fulfilled, (state, action) => {
            state.fetchCultureListState = 'succeeded'
            console.log(action.payload)
            state.cultures = action.payload
        })
        builder.addCase(fetchCultureList.rejected, (state,action) => {
            state.fetchCultureListState = 'failed'
            console.log(action.error)
        })
    }
})

export default CultureSlice