import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authoriseRequest from "../../auth";

const url = process.env.NODE_ENV === 'production' ?
    'https://labman.corticallabs.com/api/media/formulae' : `http://${window.location.hostname}:8080/media/formulae`

type RemoteState = 'idle' | 'loading' | 'succeeded' | 'failed'

export type MediaFormula = {
    ID: number,
    Name: string,
    Description: string
}

export const createFormula = createAsyncThunk("Media/createFormula", async (payload: MediaFormula) => {
    try {
        const response = await authoriseRequest(url, {
            method: 'PUT',
            body: JSON.stringify(payload)
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const fetchFormula = createAsyncThunk("Media/getFormula", async () => {
    try {
        const response = await authoriseRequest(url, {
            method: 'GET'
        })
        return response
    } catch (error) {
        console.log(error)
    }
})

export const editFormula = createAsyncThunk("Media/editFormula", async(payload: MediaFormula) => {
    try {
        const response = await authoriseRequest(url, {
            method: 'POST',
            body: JSON.stringify(payload)
        })

        return response
    } catch (error) {
        console.log(error)
    }
})

type MediaFormulaState = {
    fetchFormulaState: RemoteState,
    createFormulaState: RemoteState,
    selectedFormula: number, 
    formulae: MediaFormula[]
}

const MediaFormulaSlice = createSlice({
    name: "MediaFormula",
    initialState: {
        fetchFormulaState: 'idle',
        createFormulaState: 'idle',
        selectedFormula: 0,
        formulae: []
    } as MediaFormulaState,
    reducers: {
        setFormula(state, action) {
            state.selectedFormula = action.payload
        },
        resetSelectedFormula(state) {
            state.selectedFormula = 0
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchFormula.fulfilled, (state, action) => {
            state.fetchFormulaState = 'succeeded'
            if (action.payload !== null) {
                state.formulae = action.payload
            }
        })
        builder.addCase(fetchFormula.pending, (state) => {
            state.fetchFormulaState = 'loading'
        })
        builder.addCase(fetchFormula.rejected, (state, err) => {
            state.fetchFormulaState = 'failed'
            console.log(err)
        })
        builder.addCase(createFormula.fulfilled, (state, action) => {
            state.formulae.push(action.payload)
            state.selectedFormula = state.formulae.length
        })
    }
})

export default MediaFormulaSlice