import { useDispatch } from 'react-redux'
import { authenticate } from '../redux/UserSlice'

type LoginProps = {
    username: string
}

const Login = function(props: LoginProps) {
    const dispatch = useDispatch()
    dispatch(authenticate(props.username))

    return(
        <div>Logging In</div>
    )
}

export default Login